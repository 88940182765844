import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Destaque = ({ data }) => {

  return (
    <StyledSpotlight>
      <div className="bg" />
      <div className="title">
        <p className={font.rB + " title1"}>IMPLANTOLOGIA</p>
        <p className={font.rB + " title2"}></p>
      </div>
      <div className="content">
        <div className="contentImg">
          <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="contentTxtContainer">
          <p className={font.rSB + " subtitle"}>
            <span className="smallUnderline"></span>
            IMPLANTOLOGIA
          </p>
          <p className={font.rM + " contentTxt"}>
            Adotamos soluções diversificadas de implantes, usando técnicas diversificadas para cada caso, consoante se trate da falta de um ou mais dentes ou ausência total. Nestes casos utilizamos técnicas de TAC dentário e modelos computorizados 3D que permitem um posicionamento rigoroso dos implantes e um planeamento ajustado até à cirurgia.
          </p>
        </div>
        <div className="lineRight" />
        <Link to="/servicos/implantologia_av"><p className={font.rSB + " verticalTxt"}><span className="mydot"></span>SABER MAIS</p></Link>
      </div>
    </StyledSpotlight>
  )
}

export default Destaque

const StyledSpotlight = styled.div`
  position: relative;
  width: 100%;
  .bg{
    background-color: #eeeae6;
    position: absolute;
    height: calc(440px + 560 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      height: 1000px;
    }
    width: 92.5%;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .title{
    width: 100%;
    font-size: calc(45px + 70 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 115px;
    }
    padding-top: 6vw;
    margin: 0 auto -24px;
  }
  .title1{
    letter-spacing: 0.2em;
    margin-left: 15vw;
    color: #ffffff;
    }
  .title2{
    color: #b7a99a;
    letter-spacing: 0.2em;
    text-align: right;
    margin-right: 10vw;
  }
  .content{
    display: flex;
    align-items: center;
    margin-left: 12%;
    height: 36vw;
    overflow: hidden;
    margin-top: 4%;
  }
  .contentImg{
    width: 35vw;
  }
  .contentTxtContainer{
    position: relative;
    width: calc(305px + 365 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      width: 670px;
    }
    margin-left: 6vw;
    .subtitle{
      color: #231f20;
      font-size: calc(15px + 11 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 26px;
      }
      letter-spacing: 0.1em;
      position: relative;
    }
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      top: 1.5em;
      left: 0;
    }
    .contentTxt{
      color: #808080;
      font-size: calc(12px + 10 * (100vw - 769px) / 1151);
      margin-top: calc(20px + 35 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 22px;
        margin-top: 55px;
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
    }
  }
  .lineRight{
    position: absolute;
    bottom: calc(0px + 125 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
        bottom: 125px;
      }
    right: 0;
    height: 2px;
    width: 30%;
    background-color: #b7a99a;
  }
  .verticalTxt{
    position: absolute;
    right: 31%;
    bottom: calc(0px + 125 * (100vw - 769px) / 1151);
    font-size: calc(11px + 5 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      bottom: 125px;
      font-size: 16px;
    }
    color: #231f20;
    letter-spacing: 0.2em;
    line-height: 1em;
    transform: translateY(50%);
  }
  .mydot{
    background: #b7a99a;
    border-radius: 50%;
    height: 1em;
    width: 1em;
    position: absolute;
    left: -1.5em;
  }
`