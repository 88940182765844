import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

/* import logo from "../../images/logo_preto.svg" */

const IntroServico = ({ data }) => {
  return (
    <StyledIntro>
      <div className='container'>
        <p className={font.rSB + " title"}>
          <span className="smallUnderline"></span>
          SERVIÇOS
        </p>
      </div>
    </StyledIntro>
  )
}

export default IntroServico

const StyledIntro = styled.div`
position: relative;
.container{
  padding: 40px 40px 80px;
  .title{
    color: #231f20;
    font-size: calc(20px + 10 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 30px;
    }
    line-height: 1.2em;
    letter-spacing: 0.1em;
    position: relative;
  }
  .smallUnderline{
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    bottom: -0.4em;
    left: 0;
  }
  .sobre-intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    font-size: calc(14px + 5 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px){
      font-size: 20px;
    }
    line-height: 1.6em;
    letter-spacing: 0.1em;
    color: #808080;
    height: fit-content;
    p{
      height: fit-content;
    }
  }
} 
`
