import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Destaque = ({ data }) => {

  return (
    <StyledSpotlight>
      <p className={font.rSB + " subtitle"}>
        <span className="smallUnderline"></span>
        IMPLANTOLOGIA
      </p>
      <div className="slider">
        <div className="slides">
          <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }} />
        </div>
      </div>
      <p className={font.rM + " contentTxt"}>
        Adotamos soluções diversificadas de implantes, usando técnicas diversificadas para cada caso, consoante se trate da falta de um ou mais dentes ou ausência total. Nestes casos utilizamos técnicas de TAC dentário e modelos computorizados 3D que permitem um posicionamento rigoroso dos implantes e um planeamento ajustado até à cirurgia.
      </p>
      <p className={font.rSB + " verticalTxt hLTSaber"}>
        <Link to="/servicos/implantologia_av">
          <span className="mydot" />
          SABER MAIS
        </Link>
        <span className="myline" />
      </p>
    </StyledSpotlight>
  )
}

export default Destaque

const StyledSpotlight = styled.div`
  position: relative;
  width: 100%;
  background-color: #eeeae6;
  padding: 50px 0;
  .subtitle{
    color: #231f20;
    font-size: calc(20px + 12 * (100vw - 320px) / 448);
    line-height: 1.2em;
    letter-spacing: 0.1em;
    position: relative;
    margin: 0 35px 2em;
  }
  .smallUnderline{
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    bottom: -0.4em;
    left: 0;
  }
  .slider{
    position: relative;
    width: 90%;
    height: 50vh;
  }
  .slides{
    position: relative;
    height: 100%;
  }
  .contentTxt{
    color: #808080;
    font-size: calc(14px + 5 * (100vw - 320px) / 448);
    line-height: 1.6em;
    letter-spacing: 0.1em;
    padding: 35px;
  }
  .verticalTxt{
    color: #231f20;
    font-size: calc(12px + 4 * (100vw - 320px) / 448);
    letter-spacing: 0.2em;
    display: flex;
    align-items: center;
  }
  .mydot{
    margin: 0 12px 0 35px;
    background: #b7a99a;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  .myline{
    margin-left: 12px;
    background: #b7a99a;
    height: 2px;
    flex-grow: 1;
  }
`