import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

/* import logo from "../../images/logo_preto.svg" */

const IntroServico = ({ data }) => {
  return (
    <StyledIntro>
      <div className="content">
        <p className={font.rSB + " title"}>
          SERVIÇOS
          <span className="smallUnderline" />
        </p>
        
      </div>
    </StyledIntro>
  )
}

export default IntroServico

const StyledIntro = styled.div`
  position: relative;
  width: 100%;

  .img{
    background-image: url(${props => props.img && props.img});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 60%; */
    height: 600px;
    z-index: -1;
    /* position: absolute; */
    top: 0;

    @media only screen and (max-width: 1025px){
      height: 500px;
    }

    @media only screen and (min-width: 2000px){
      height: 900px;
    }
  }

  .logo {
    display: block;
    margin: 0 auto 6vw;
    width: 20%;
  }

  .content {
    width: calc(630px + 400 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      width: 950px;
    }
    margin: 0 auto 0;
    padding: 5% 0 0 0;
    background-color: #fff;
    position: relative;

    .title {
      font-size: calc(22px + 4 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 26px;
      }
      letter-spacing: 0.1em;
      color: #231f20;
      position: relative;
      margin-bottom: 2em;

      .smallUnderline {
        width: 2em;
        height: 0.25em;
        background-color: #b7a99a;
        position: absolute;
        top: 1.6em;
        left: 0;
      }
    }
  }

  .sobre-intro {
    font-size: calc(12px + 10 * (100vw - 769px) / 1151);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    margin: 0 auto;
    letter-spacing: 0.1em;
    color: #808080;
    text-align: center;
    line-height: 1.6em;
    height: 400px;
    p {
      height: fit-content;
    }
  }
`
