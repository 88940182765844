import React from "react"

import styled from "styled-components"

import ServicoCardMobile from "../cards/servicoMobile"

const Servicos = ({ data }) => {

  return(
    <StyledServicos>
      {data.map((data, i)=>(
        <ServicoCardMobile data={data} key={"servicos" + i}/>
      ))}
    </StyledServicos>
  )
}

export default Servicos

const StyledServicos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4vw;
  grid-row-gap: 6vw;
  width: 85%;
  margin: 8vw auto;
`