import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Destaque from "../../components/destaque/servicosDestaque"
import DestaqueMobile from "../../components/destaque/servicosDestaqueMobile"
import Servicos from "../../components/list/servicos"
import ServicosMobile from "../../components/list/servicosMobile"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import Intro from "../../components/list/intro"
import IntroMobile from "../../components/list/intromobile"

const ServicosPage = ({ data }) => (
  <Layout>
    <SEO title="Serviços" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <DestaqueMobile data={data.servicosJson.destaque} />
        <IntroMobile />
        <ServicosMobile data={data.servicosJson.servicos} />
        <FooterMobile data={data.globalJson.footer} hasAppointmentButton />
      </>
    ) : (
      <>
        <Navbar />
        <Destaque data={data.servicosJson.destaque} />
        <Intro />
        <Servicos data={data.servicosJson.servicos} />
        <Footer data={data.globalJson.footer} hasAppointmentButton />
      </>
    )}
  </Layout>
)

export default ServicosPage

export const Json = graphql`
  query servicos {
    servicosJson {
      destaque {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      servicos {
        nome
        link
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        detalhes
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
